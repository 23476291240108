<template>
  <div>

    <div class="image-about-company">
      <div class="info-title">У ВОДЫ - это</div>
      <dvi style="display: grid; grid-template-columns: 33% 33% 33%;">
        <div>
          <div class="info-number" id="my1Element">5</div>
          <div class="info-text">завершённых</div>
          <div class="info-text">проектов</div>
        </div>
        <div>
          <div class="info-number" id="my2Element">5</div>
          <div class="info-text">посёлков</div>
          <div class="info-text">в продаже</div>
        </div>
        <div>
          <div class="info-number" id="my3Element">+</div>
          <div class="info-text">земельных участков</div>
          <div class="info-text">в продаже</div>
        </div>
      </dvi>
    </div>


    <div class="about-company-info other-text-style">
      <img src="../assets/DJI_0046.jpg" style="width: 100%"/>
      <div class="about-company-info-text other-text-style">
        <p>Создание коттеджных поселков – основное направление нашей работы.
         Мы:облагораживаем и обустраиваем территорию;
          проводим коммуникации и создаем инфраструктуру для жизни;
          обеспечиваем сохранность благоустройства и атмосферы в каждом поселке.
         Мы работаем для того, чтобы вам не пришлось ни о чем думать. Проводим оптоволокно,
          кладем асфальт и создаем условия для транспортного обеспечения,
          обустраиваем общественные зоны и места для отдыха, делаем детские
          и спортивные площадки – остается только заселиться и получать
          удовольствие от жизни в окружении живописной природы.</p>
      </div>
    </div>

  </div>
</template>
<script>
import { CountUp } from 'countup.js';

export default {
  name: 'AboutCompany',
  components: {
  },
  data() {
    return {
    }
  },

  mounted () {
    this.demoTest();
  },
  methods: {
    demoTest: function () {
        let demo3 = new CountUp('my3Element', 350);
        let demo1 = new CountUp('my1Element', 5);
        let demo2 = new CountUp('my2Element', 5);
        if (!demo3.error) {
          demo3.start();
          demo1.start();
          demo2.start();
        } else {
          console.error(demo3.error);
        }
    },
  },
}
</script>
<style scoped>
.image-about-company {
  background-image: url("../assets/BG-fon_green.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 24px
}
.about-company-info{
  display: grid;
  grid-template-columns: 50% 50%;
  box-sizing: border-box;
  gap: 10px;
  padding-left: 24px;
  align-items: center;
}
.about-company-info-text{
  font-size: 30px;
  padding: 16px;
  /*text-indent: 32px;*/
  text-align: justify;
}
.info-number{
  font-size: 62px;
  color: #5d6c48
}
.info-title{
  font-size: 40px;
  font-weight: 700;
  color: white
}
p{
  margin-bottom: 6px;
}
@media (max-width: 1600px) {
  .about-company-info-text{
    font-size: 22px;
  }

}
@media (max-width: 768px) {
  .about-company-info{
    display: block;
    padding: 20px;
    /*grid-template-columns: 100%;*/
  }
  .about-company-info-text{
    font-size: 18px;
  }
  .info-number{
    font-size: 24px;
  }
  .info-text{
    font-size: 10px;
  }
  .info-title{
    font-size: 32px;
  }
}
</style>
