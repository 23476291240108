<template>
  <div class="main--header">
    <div style="display: flex; justify-content: start">
      <img :style="width > 768 ? 'width: 150px' : 'width: 100px'" class="style-img-logo" src="../assets/logo.png"  @click="$router.push('/')"/>
    </div>

    <div v-if="500">
        <IconList style="margin-right: 36px"/>
    </div>



    <div v-if="width > 900" cols="6" align-self="center" style="display: flex; justify-content: space-around; gap: 32px">
      <div  class="btn">
        <router-link class="btn"  to="/villages">Посёлки</router-link>
      </div>
      <div  class="btn" style="text-decoration: none;">
        <router-link class="btn" to="/about">О компании</router-link>
      </div>
<!--      <div  class="btn" variant="text">-->
<!--        <router-link class="btn" to="/map" style="text-decoration: none;">Карта</router-link>-->
<!--      </div>-->
      <div  class="btn" variant="text">
        <router-link class="btn" to="/news">Новости</router-link>
      </div>
      <div class="btn" variant="text">
        <router-link class="btn" to="/contacts">Контакты</router-link>
      </div>
    </div>
    <div v-if="width > 768" align-self="center">
      <div class="">
        +7 (812) 200 77-55
      </div>

    </div>
    <div class="container nav-container" @click="isDialogOpen = !isDialogOpen">
      <input class="checkbox" type="checkbox" name="" id="" />
      <div class="hamburger-lines">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
    </div>
  </div>

  <v-dialog v-model="isDialogOpen" height="100%" width="100%" style="" fullscreen>
    <div style="position: absolute; left: 8px; top: 32px">
      <img style="width: 150px" src="../assets/logo_white.png"  @click="$router.push('/')"/>
    </div>
    <div class="dialog-inside">
      <div class="dialog-close" @click="isDialogOpen = !isDialogOpen"></div>
      <div class="dialog-text">
        <div @click="navigateTo('/')" class="nav-dialog">Коттеджные посёлки <span style="font-size: 24px">У ВОДЫ</span></div>
        <div @click="navigateTo('/villages')" class="nav-dialog">Посёлки</div>
        <div @click="navigateTo('/about')" class="nav-dialog">О компании</div>
        <div @click="navigateTo('/news')" class="nav-dialog">Новости</div>
        <div @click="navigateTo('/contacts')" class="nav-dialog" style="margin-bottom: 32px;">Контакты</div>
        <!--      </div>-->
        <!--      <div class="dialog-text">-->
        <div>+7 812 200-77-55</div>
        <div class="dialog-subtext">Санкт-Петербург,</div>
        <div class="dialog-subtext">7-я Советская улица 23/24</div>
        <div class="dialog-subtext">Ежедневно | 10:00 – 20:00 (по предварительной записи)</div>
        <IconList style="margin-top: 12px"/>
      </div>
    </div>

  </v-dialog>
</template>


<script>
import { useWindowSize } from '@vueuse/core'
import IconList from '@/components/IconList.vue'
const { width } = useWindowSize()

export default {
  name: 'MainHeader',
  components: {
    IconList,
  },
  data() {
    return {
      width: width,
      isDialogOpen: false,
    }
  },
  methods: {
    navigateTo(to) {
      this.isDialogOpen = false;
      this.$router.push(to)
    }
  }
}
</script>

<style scoped>

.btn {
  font-size: 18px;
  color: #3E3E3D;
  text-decoration: none;
}
.main--header {
  /*box-shadow: 0 0 30px 0px rgba(0, 0, 0, .5);*/
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  padding: 24px 0px;
  /*border: 1px solid;*/
  display: flex;
  justify-content: space-between;
  /*max-width: 1280px;*/
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  /*padding: 16px;*/
  align-items: center;
}




.navbar {
  width: 100%;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar .nav-container a:hover{
  font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 24px;
  right: 16px;
  z-index: 999999;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: -10px;
  right: 16px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}



.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}
.nav-dialog:hover{
  cursor: pointer;
  text-decoration: underline;
}
.dialog-text{
  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
  font-size: 20px;
  font-weight: 700;
  color: white;


}
.dialog-subtext{
  font-size: 12px;
}
.dialog-close{
  position: absolute;
  right: 30px;
  top: 45px;
  width: 30px;
  height: 30px;
  background-color: white;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}
.dialog-inside{
  padding: 16px;
  height: 100%;
  width: 100%;
  background-image: url('/src/assets/BG-fon_green.png');
  background-color: #3E3E3D;
}

</style>
