<template>
  <div class="form-answer">
    <div class="form-answer-container">
      <div class="form-title">
        ОСТАЛИСЬ ВОПРОСЫ?
        <br/>
        СВЯЖИТЕСЬ С НАМИ
        <br/>
        И МЫ ОТВЕТИМ НА НИХ
      </div>
      <div style="align-self: center">
        <form class="grid-inputs-form" id="my-form" action="https://formspree.io/f/mleyboyz" method="POST">
          <input type="hidden" name="_captcha" value="false">
          <div>
            <v-text-field
              v-model="name"
              label="Ваше имя"
              bg-color="none"
              name="name"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="phone"
              :counter="7"
              label="Номер телефона"
              size="xs"
              type="phone"
              bg-color="none"
              name="phone"
            ></v-text-field>
          </div>
          <!--        скрытый для перехода после сабмита-->
          <!--        <input type="hidden" name="_next" :value="window.location.origin">-->
          <input type="hidden" name="_next" value="https://u-vody.ru/">
          <div>
            <v-text-field
              v-model="email"
              label="E-mail"
              bg-color="none"
              size="xs"
              name="email"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="message"
              label="Ваш вопрос"
              size="xs"
              bg-color="none"
              name="message"
            ></v-text-field>
          </div>
          <div>
            <v-btn
              style="margin-top: 12px"
              class="me-4"
              color="white"
              type="submit"
              rounded
              min-width="225"
              min-height="57"
              variant="outlined"
              size="xs"
              @click="sendForm"
            >
              Отправить запрос
            </v-btn>
          </div>
        </form>
        <p id="my-form-status" style="margin-left: 16px;"></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormContacts',
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      message: "",
    }
  },
  mounted () {
    var form = document.getElementById("my-form");

    async function handleSubmit(event) {
      event.preventDefault();
      var status = document.getElementById("my-form-status");
      var data = new FormData(event.target);
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          status.innerHTML = "Ваше сообщение успешно доставлено.";
          form.reset()
        } else {
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
            } else {
              status.innerHTML = "Oops! Какая-то ошибка, сообщение не доставлено"
            }
          })
        }
      }).catch(() => {
        status.innerHTML = "Oops! Какая-то ошибка, сообщение не доставлено"
      });
    }
    form.addEventListener("submit", handleSubmit)
  },
  methods: {
    sendForm(e) {
      console.log(333, e)
      setTimeout(() => {
        this.clearForm();
      }, 111)
    },
    clearForm() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.message = "";
    },
  }
}
</script>

<style scoped>
.form-answer{
  background-color: gray;
}
.form-answer-container {
  max-width: 1280px;
  margin: 0 auto;
}
.form-title{
  font-size: 35px; color: white;
  align-self: center;
  padding-left: 54px;
  margin-bottom: 12px;
  padding-top: 24px;
}
.grid-inputs-form{
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 6px;
  padding: 16px;
}
@media (max-width: 768px) {
  .form-title{
    font-size: 18px;
  }
  .grid-inputs-form{
    grid-template-columns: 100%;
  }

}

</style>
