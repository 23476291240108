<template>
<!--  <div class="village-card-title">НАШИ ПОСЁЛКИ</div>-->
  <div class="village-card">
    <div
      class="image container"
      v-for="card in cards"
      :key="card"
      @click="toVillagePage(card)"
    >
      <v-lazy-image :src="card?.src" loading="lazy" style="width: 100%; height: 100%" />
      <div class="bottom-left">
<!--          <div style="font-size: 32px">-->
<!--            {{card.title}}-->
<!--          </div>-->
<!--          <div style="font-size: 16px; padding-left: 16px" v-if="width > 768">-->
<!--            <ul>-->
<!--              <li v-for="liItem in card.pluses" :key="liItem">{{liItem}}</li>-->
<!--            </ul>-->
<!--          </div>-->
          <div class="custom-btn">
              {{ card.id === 11 ? 'Выбрать дом' : 'Выбрать участок' }}
          </div>
        </div>
    </div>
  </div>
  <div class="village-card-title">ЗАВЕРШЁННЫЕ</div>

  <div class="village-card">
    <div
      class="image container"
      v-for="card in cardsSOLD"
      :key="card"
      @click="toVillagePage(card)"
    >
      <v-lazy-image :src="card?.src" loading="lazy" style="width: 100%; height: 100%; object-fit: cover" />
      <div class="bottom-left">
        <!--          <div style="font-size: 32px">-->
        <!--            {{card.title}}-->
        <!--          </div>-->
        <!--          <div style="font-size: 16px; padding-left: 16px" v-if="width > 768">-->
        <!--            <ul>-->
        <!--              <li v-for="liItem in card.pluses" :key="liItem">{{liItem}}</li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <div class="custom-btn">
          Выбрать участок
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";

import { useWindowSize } from '@vueuse/core/index'
import admiral from '../assets/imgsTitle/адмиралТ.jpg';
import akvarel from '../assets/imgsTitle/акварельТ.jpg';
import alieParusa from '../assets/imgsTitle/алыепарусаТ.jpg';
import brusnika from '../assets/imgsTitle/брусникаТ.jpg';
import verchDacha from '../assets/imgsTitle/верхняяДачаТ.jpg';
import dostoevskii from '../assets/imgsTitle/достоевскийТ.jpg';
import repola from '../assets/imgsTitle/репола1.jpg';
import repolaPark from '../assets/imgsTitle/реполаПарк.jpg';
import chufrino from '../assets/imgsTitle/чуфриноТ.jpg';
import yapillya from '../assets/imgsTitle/япилляТ.jpg';
import club from '../assets/imgsTitle/клубныйТ.jpg';


const { width } = useWindowSize()
export default {
  name: 'VillageCard',
  props: {
    msg: String,
    card: String,
  },
  components: {
    "v-lazy-image": VLazyImage,
  },
  data () {
    return {
      width: width,
      cards: [
          {
              id: 2,
              src: alieParusa,
              name: 'Алыепаруса',
              status: 'notSOLD',
              pluses: {
                  "Кол-во участков": 212,
                  "Площадь участков": "Земли поселений, ИЖС",
                  "Статус земли": "Посёлок Пески",
                  "Водоёмы": "Финский залив",
                  "Срок сдачи": "2024",
                  "Старт продаж": "1 сентября 2021",
              },
          },
        {
          id: 1,
          src: yapillya,
          name: 'Япилля',
          status: 'notSOLD',
          pluses: {
            "Кол-во участков": null,
            "Площадь участков": null,
            "Статус земли": null,
            "Водоёмы": null,
            "Срок сдачи": null,
            "Старт продаж": null,
          },
        },
        {
          id: 7,
          src: repola,
          name: "Репола",
          status: 'notSOLD',
          pluses: {
            "Кол-во участков": 92,
            "Площадь участков": "от 12 до 17 соток",
            "Статус земли": "Для ведения дачного хозяйства",
            "Водоёмы": "Выборгский залив",
            "Срок сдачи": "Лето 2022",
            "Старт продаж": "1 июля 2021",
          },
        },
          {
              id: 11,
              src: club,
              name: "клубный-дом",
              status: 'notSOLD',
          },
        {
              id: 5,
              src: repolaPark,
              name: "РеполаПарк",
              status: 'SOLD',
              pluses: {
                  "Кол-во участков": 46,
                  "Площадь участков": "для дачного строительства",
                  "Статус земли": "Выборгский район, посёлок Подборовье",
                  "Водоёмы": "На берегу\n Финского залива",
                  "Срок сдачи": "Сдан",
                  "Старт продаж": "",
              },
          },
          {
              id: 6,
              src: brusnika,
              name: "БрусникRoad",
              status: 'notSOLD',
              pluses: {
                  "Кол-во участков": 93,
                  "Населённый пункт": "СПК Шестаковский",
                  "Статус земли": "Для ведения дачного хозяйства",
                  "Водоёмы": "Озеро Кленовское, Сайменский канал",
                  "Срок сдачи": "1 мая 2021",
                  "Старт продаж": "Май 2022",
              },
          },
      ],
      cardsSOLD: [
        //снизу проданные участки
        {
          id: 4,
          src: admiral,
          name: "Адмирал",
          status: 'SOLD',
          pluses: {
            "Кол-во участков": 50,
            "Площадь участков": "Земли поселений",
            "Статус земли": "Посёлок Овсяное",
            "Водоёмы": "Нахимовское озеро",
            "Срок сдачи": "Сдан",
            "Старт продаж": "20 марта 2020",
          },
        },
        {
          id: 8,
          src: akvarel,
          name: "Акварель",
          status: 'SOLD',
          pluses: {
            "Кол-во участков": 19,
            "Населённый пункт": "Посёлок Овсяное",
            "Статус земли": "Земли поселений",
            "Водоёмы": "Озеро Нахимовское",
            "Срок сдачи": "1 августа 2019",
            "Старт продаж": "Сдан",
          },
        },
        {
          id: 9,
          src: chufrino,
          name: "Чуфрино",
          status: 'SOLD',
          pluses: {
            "Кол-во участков": 34,
            "Площадь участков": "от 12 до 16 соток",
            "Статус земли": "Земли поселений",
            "Водоёмы": "Озеро Нахимовское",
            "Срок сдачи": "Сдан и заселён",
            "Старт продаж": "Сдан",
          },
        },
        {
          id: 10,
          src: verchDacha,
          name: "Верхняядача",
          status: 'SOLD',
          pluses: {
            "Кол-во участков": 132,
            "Площадь участков": "Для ведения дачного строительства",
            "Статус земли": "для дачного строительства",
            "Водоёмы": "Финский залив",
            "Срок сдачи": "1 мая 2017",
            "Старт продаж": "Сдан",
          },
        },
        {
              id: 3,
              src: dostoevskii,
              name: "Достоевский",
              status: 'notSOLD',
              pluses: {
                  "Кол-во участков": 17,
                  "Площадь участков": "от 12 до 17 соток",
                  "Статус земли": "Земли поселений, ИЖС",
                  "Водоёмы": "Рощинское озеро",
                  "Срок сдачи": "Сдан",
                  "Старт продаж": "20 июня 2021",
              },
          },

      ]
    }
  },
  methods: {
    toVillagePage(card) {
      this.$router.push(`/poselki/${card.name}`);
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
}

</script>

<style scoped>
.custom-btn{
  font-size: 16px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}

.image {
  overflow:hidden;
  width: 100%;
  height:100%;
}
.image img {
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
    object-fit: cover;
}

.image img:hover{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 60px;
  left: 16px;
  text-align: start;
}
/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
  color: white;

  /*max-width: 1050px;*/
  /*width: 95%;*/
  /*margin: auto;*/
}
.village-card{
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3px;
  padding: 0;
}
.village-card-title{
  min-height: 100px;
  font-size: 40px;
  font-weight: 700;
  padding: 16px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .village-card{
    display: flex;
    flex-direction: column;
    /*grid-template-columns: 100%;*/
  }
  .village-card-title{
    font-size: 22px;
  }
}
</style>
