<template>
  <div class="grid-icon">
    <div v-for="icon in icons" :key="icon">
      <a :href="icon.value">
        <img :src="icon.svg" alt="my-logo" class="item-icon" />
      </a>
      <!--        {{ icon.svg }}-->
    </div>
  </div>
</template>

<script>
import tgIcon from '@/assets/icons/TelegramIcon.svg'
import instIcon from '@/assets/icons/free-icon-instagram.png'
import vkIcon from '@/assets/icons/free-icon-vk.png'
import odnoklasIcon from '@/assets/icons/iconOdnokl.png'
import youtube from '@/assets/icons/free-icon-youtube-160205.png'

export default {
  name: 'IconItem',
  data() {
    return{
      icons: [
        {
          id: 1,
          name: "ВК",
          value: "https://vk.com/u_vody_ru",
          svg: vkIcon,
        },
        {
          id: 2,
          name: "INST",
          value: "https://instagram.com/uvodyspb",
          svg: instIcon,

        },
        {
          id: 3,
          name: "Телеграмм",
          value: "https://t.me/u_vody",
          svg: tgIcon,
        },
        {
          id: 4,
          name: "Одноклассники",
          value: "https://ok.ru/group/70000002148434",
          svg: odnoklasIcon,
        },
        {
          id: 5,
          name: "Ютуб",
          value: "https://www.youtube.com/channel/UCzFb-jymcjQT94Lcd5ApcVA",
          svg: youtube,
        },
      ],
    }
  }
}
</script>

<style scoped>
.item-icon{
  width: 32px;
  height: 32px;
}
.grid-icon{
  display: flex;
  gap: 12px;
}
@media (max-width: 600px) {
  .item-icon{
    width: 26px;
    height: 26px;
  }
  .grid-icon{
    margin-right: 36px;
  }
}
</style>
