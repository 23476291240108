<template>
<!--    карта-->
  <div class="contacts-box" justify="center" no-gutters>
    <div class="map" style="position:relative;overflow:hidden;">
      <a href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
         style="color:#eee;font-size:12px;position:absolute;top:0px;">Санкт‑Петербург</a>
      <a href="https://yandex.ru/maps/2/saint-petersburg/house/7_ya_sovetskaya_ulitsa_23_24/Z0kYdQBkTkEDQFtjfXVydHxgbA==/?ll=30.374082%2C59.933748&utm_medium=mapframe&utm_source=maps&z=16.4"
         style="color:#eee;font-size:12px;position:absolute;top:14px;">
        7-я Советская улица, 23/24
      </a>
      <iframe src="https://yandex.ru/map-widget/v1/?ll=30.374082%2C59.933748&mode=whatshere&whatshere%5Bpoint%5D=30.374513%2C59.935016&whatshere%5Bzoom%5D=17&z=16.4"
              width="100%" height="100%"
              frameborder="1"
              allowfullscreen="true"
              style="position:relative;"
      ></iframe>
    </div>
<!--      <img src="src/assets/BG-fon_green.png" style="width: 100%;">-->
    <div class="contacts">
      <div class="box-wrapper">
        <div class="title">Центральный офис:</div>
        <div class="subtitle other-text-style">г. Санкт-Петербург, 7-я Советская улица 23/24</div>
        <div class="subtitle other-text-style">Ежедневно | 10:00 – 20:00 (по предварительной записи)</div>
      </div>
      <div class="box-wrapper">
        <div class="title">Дополнительный офис:</div>
        <div class="subtitle other-text-style">г. Выборг, ул. Некрасова 11</div>
        <div class="subtitle other-text-style">Пн - Пт | 10:00 – 18:00 (по предварительной записи)</div>
      </div>
      <div class="box-wrapper">
        <div class="title">Телефон:</div>
        <div class="subtitle other-text-style">+7 812 200-77-55</div>
      </div>
      <div class="box-wrapper">
        <div class="title">Почта:</div>
        <div class="subtitle other-text-style" style="margin-bottom: 12px">info@u-vody.ru</div>
        <IconList />
      </div>
    </div>
  </div>

</template>

<script>

import IconList from '@/components/IconList'

export default {
  name: 'ContactsCompany',
  components: {
    IconList,
  }
}
</script>

<style scoped>
.contacts-box{
  display: flex;
  flex-direction: row;
}
.map {
  width: 100%;
  height: 500px;
}
.contacts {
  background-image: url("../assets/BG-fon_green.png");
  width: 105%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  box-sizing: border-box;
  color: white;
  gap: 16px;
}
.title{
  font-size: 20px;
  font-weight: bold;
}
.subtitle {
  font-size: 16px;
}
@media (max-width: 1200px) {
  .contacts-box{
    display: block;
  }
}
</style>
