<template>
  <div class="footer">
    <div class="footer-content">
      <div class="content1">
        <img class="footer-img" src="../assets/logo_white.png">
      </div>
      <div class="content2">
        <div style="color: #138048">Навигация</div>
        <div @click="scroll('/villages')" class="footer-nav">Посёлки</div>
        <div @click="scroll('/about')" class="footer-nav">О компании</div>
        <div @click="scroll('/news')" class="footer-nav">Новости</div>
        <div @click="scroll('/contacts')" class="footer-nav">Контакты</div>
      </div>
      <div class="content3">
        <div>+7 812 200-77-55</div>
        <div>г. Санкт-Петербург,</div>
        <div>7-я Советская улица 23/24</div>
        <div>info@u-vody.ru</div>
<!--        <div>111</div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCompany",
  methods: {
    scroll(to) {
      setTimeout(() => {
        this.$router.push(to)
      }, 0)
      const el = document.getElementById("main-scroll");
      el.scrollIntoView({block: "start", behavior: "smooth"})
    },
  },
}

</script>

<style scoped>
.footer{
  padding: 70px 0px;
  background-color: #111;
}
.footer-content{
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  padding: 16px;
}
.content1{
  padding: 24px;
  /*background-image: url("../assets/logo_white.png");*/
}
.content2{
  font-size: 14px;
  color: white;
  display: grid;
  gap: 8px;
}
.content3{
  text-align: right;
  color: white;
}
.footer-img{
  width: 150px;
}
.footer-nav{
  text-decoration: none;
  color: white;
}
@media (max-width: 768px) {
  .footer{
    padding: 16px;
  }
  .footer-content{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: none;
    grid-template-areas:
    "content1 content1"
    "content2 content3";
  }
  .content1{
    grid-area: content1;
    padding: 0 0 16px 0;
  }
  .content2{
    grid-area: content2;
  }
  .content3{
    grid-area: content3;
    font-size: 10px;
  }
  .footer-img{
    width: 150px;
  }
}
</style>
