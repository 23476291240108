<template>
  <HelloWorld class="example" msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'




// import { getStorage } from 'firebase/storage'
// import { getDatabase, ref as testRef, set, onValue } from 'firebase/database'
// import { doc, setDoc, getFirestore } from 'firebase/firestore'



export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data: () => {
    return {

    }
  },
  created () {
    // this.createUser();
  },
  // id="infotext_1"
  mounted () {
    document.title = 'У воды';
    try {
      const script = document.createElement('script');
      script.src ="https://mc.yandex.ru/metrika/tag.js";
      script.async = true;
      script.onload = () => {
        window.Ya.Metrika2 = new window.Ya.Metrika2({
          id: 95047419,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });
      };
      script.onerror = (error) => {
        console.error('Failed to load Yandex Metrica script', error);
      };
      document.head.appendChild(script);
    } catch (error) {
      console.error('An error occurred while loading Yandex Metrica script', error);
    }
  },
  methods: {
    // async createDocData() {
    //   const db = getFirestore(app);
    //   for (let i = 0; i < this.yapillya.length; i++) {
    //     console.log(1);
    //     await setDoc(doc(db, "япилля", String(i + 1)), this.yapillya[i])
    //   }
    // },

    // async getDataFromBase() {
    //   const db = getDatabase(app);
    //   const starCountRef = testRef(db, '/poselki');
    //   console.log(9, "starCountRef", starCountRef)
    //   await onValue(starCountRef, (snapshot) => {
    //     console.log(666, 'snapshot', snapshot)
    //     const data = snapshot.val();
    //     console.log(555, 'data', data)
    //   });
    // },
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Aqum,serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*max-width: 1280px;*/
  margin: 0 auto;
  /*font-family: Aq;*/
}
.other-text-style{
  /*font-family: cursive;*/
  font-family: Poiret One,serif;
}

@font-face {
  font-family: "Aqum";
  src: url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.eot");
  src: url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/ff91cc5ae4aa509a3ea28aced1398b92.svg#Aqum") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Poiret One";
  src: url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.eot");
  src: url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/3b585e0e2f91bef9ab6ea74f7e302f20.svg#Poiret One") format("svg");
}
.example {
  -webkit-transform: translateX(50px); /* для Safari, Chrome */
  -moz-transform: translateX(50px); /* для Firefox */
  -ms-transform: translateX(50px); /* для Internet Explorer */
  -o-transform: translateX(50px); /* для Opera */
  transform: translateX(50px);
}
</style>
