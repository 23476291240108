import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAMBfvUaJGtyBOnB37IpQ4xiTci3uq-3R8",
  authDomain: "nearwater-c02a9.firebaseapp.com",
  projectId: "nearwater-c02a9",
  storageBucket: "nearwater-c02a9.appspot.com",
  messagingSenderId: "957889099650",
  appId: "1:957889099650:web:1616ed42ffc0d428d5819b",
  measurementId: "G-69XPGEGNZS"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const dbF = getFirestore(app);

export default dbF;
