import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import lazyPlugin from 'vue3-lazy'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// import { initializeApp } from 'firebase/app';
// import { getStorage } from 'firebase/storage';

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(lazyPlugin, {
  loading: 'loading.png',
  error: 'error.png'
}).use(router).use(vuetify).mount('#app')
