<template>
  <div style="margin: 0 auto">
      <div class="container-village">
        <v-lazy-image v-if="width > 768" :src="data?.[this.id].pos" loading="lazy" style="max-height: 500px; margin: 0 auto" />
        <v-lazy-image v-else :src="data?.[this.id].posMobile" loading="lazy" style="max-height: 500px; margin: 0 auto" />

          <div class="village-title">{{ this.id === 'клубный-дом' ? 'О клубном доме' : 'О посёлке' }}</div>

          <div class="about-container other-text-style">
          <div v-html="data?.[this.id].about"></div>
        </div>
    </div>

    <div class="rb-icon_info" style="margin-bottom: 24px">
      <div class="icon_info_item" v-for="(svg, i) in data?.[this.id].pluses" :key="i">
        <div class="icon_info_item__icon">
          <img :src="svg.svg" alt="icon">
        </div>
        <div class="icon_info_item__title other-text-style">
          {{ svg.name }}
        </div>
        <div class="icon_info_item__desc other-text-style" style="text-align: center">{{ svg.value }}</div>
      </div>
    </div>

    <div class="wrapper-li" style="margin-bottom: 24px">
      <div class="container-li">
        <div v-for="plus in data?.[this.id].infrastructures" :key="plus" class="row-li other-text-style">
          <img src="../assets/smallHouseIcon.png" style="width: 32px"/>
          <p class="text-text">
            {{plus}}
          </p>
        </div>
      </div>
    </div>

      <div v-if="this.id === 'клубный-дом'" class="wrapper-9-key-images">
          <img
            v-for="(item, i) in data?.[this.id]?.images"
            :src="item"
            :key="i"
          />
      </div>


    <div id="main-svg-container" class="container example" v-if="data?.[this.id]?.svg || data?.[this.id]?.svgMobile">
        <InlineSvg
          :src="data?.[this.id]?.svg"
          class="box"
          :class="this.id === 'РеполаПарк' ? 'svg-full-width' : ''"
          style="z-index: -1"
        />
        <InlineSvg
          :src="data?.[this.id]?.svgMobile"
          class="stack-top"
          :class="this.id === 'РеполаПарк' ? 'svg-full-width' : ''"
          ref="refInfoSvg"
          id="InlineSvgToStyled"
        />
    </div>
  </div>

</template>


<script>
import { useRoute } from 'vue-router';
import svg1 from '../assets/svgIcons/res-1.svg';
import svg2 from '../assets/svgIcons/resurs-2.svg';
import svg3 from '../assets/svgIcons/resurs-3.svg';
import svg4 from '../assets/svgIcons/resurs-4.svg';
import svg5 from '../assets/svgIcons/resurs-5.svg';
import svg6 from '../assets/svgIcons/resurs-6.svg';
import pos1 from '../assets/imgsAbout/pos1.jpg';
import pos2 from '../assets/imgsAbout/pos2-min.png';
import pos3 from '../assets/imgsAbout/pos3-min.png';
import pos4 from '../assets/imgsAbout/pos4-min.png';
import pos5 from '../assets/imgsAbout/pos5-min.png';
import pos6 from '../assets/imgsAbout/pos6-min.png';
import pos7 from '../assets/imgsAbout/pos7-min.png';
import pos8 from '../assets/imgsAbout/pos8-min.png';
import pos9 from '../assets/imgsAbout/pos9-min.png';
import pos10 from '../assets/imgsAbout/pos10-min.png';

import yapi from '../assets/imgsAboutHowMainPage/япилля.png';
import admi from '../assets/imgsAboutHowMainPage/адмирал.png';
import akva from '../assets/imgsAboutHowMainPage/акварель.png';
import alie from '../assets/imgsAboutHowMainPage/алыеПаруса.png';
import brus from '../assets/imgsAboutHowMainPage/брусника.png';
import verch from '../assets/imgsAboutHowMainPage/верхняя.png';
import dosto from '../assets/imgsAboutHowMainPage/достоевский.png';
import repo from '../assets/imgsTitle/репола1.jpg';
import repolaPark from '../assets/imgsTitle/реполаПарк.jpg';
import chuf from '../assets/imgsAboutHowMainPage/чуфрино.png';

//еще не проданные
import svgRepola from '../../src/components/svgComponents/svgRepola.svg';
import svgRepolaMobile from './svgMobile/svgRepoMobile.svg'
import svgBrus from '../../src/components/svgComponents/brusSVG.svg';
import svgBrusMobile from './svgMobile/svgBrusMobile.svg'
import svgDosto from '../../src/components/svgComponents/plan-dostoevskij-mobilnyj-2.svg';
import svgDostoMobile from './svgMobile/svgDostoMobile.svg'
// import svgAlie from '../../src/components/svgComponents/alyeSVG.svg';
// import svgAlieMobile from './svgMobile/svgAlieMobile.svg'
// import svgYappi from '../../src/components/svgComponents/yappiSVG.svg';
import svgYappi from '../../src/components/svgComponents/yappi2SVG.svg';
import alyeSVGTest from '../../src/components/svgComponents/alyeSVGTest.svg';

//проданные
import svgRepoPark from './svgComponents/svgRepoParkIMG.svg';
// import svgRepoParkMobile from './svgMobile/svgRepoParkMobile.svg'
import svgAdmiMobile from './svgMobile/svgAdmiMobile.svg'
import svgAdmi from './svgComponents/svgAdmi.svg';
import svgVerch from './svgComponents/svgVerchDashaMobile.svg';
import svgChuf from '../../src/components/svgComponents/svgChuf.svg';
import svgAkva from '../../src/components/svgComponents/svgAkva.svg';


// import store from '../../public/store/dosto.json'

import { useWindowSize } from '@vueuse/core/index';
import InlineSvg from "vue-inline-svg";
// import { defineAsyncComponent } from 'vue';
import VLazyImage from "v-lazy-image";

import { doc, updateDoc, getDocs, query, collection } from "firebase/firestore";
// import { ref, getStorage, getDownloadURL } from "firebase/storage";
import dbF from '../firebase'
import { unref } from 'vue'

export default {
  name: 'VillageAbout',
  components: {
    // svgRepoInfo,
    InlineSvg,
    "v-lazy-image": VLazyImage,
  },
  data() {
    return {
      currentPos: [],
      app: null,
      width: useWindowSize().width,
      test: null,
      id: useRoute().params.id,
      svgData: [ svg1, svg2, svg3, svg4, svg5, svg6],
      data: {
          "клубный-дом": {
              id:12,
              dbName:"клубный-дом",
              // dbName:"9-key",
              images: [
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-01.jpg?alt=media&token=9a7644cf-d32c-4789-91a2-fabb6a3821cc',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-02.jpg?alt=media&token=e4a9a78c-ce69-419b-ab98-bbfe37f72bc5',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-03.jpg?alt=media&token=89e8e06c-5cbd-4c48-9d46-db57dec0a98b',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-04.jpg?alt=media&token=62265984-58b1-4cf6-8f13-5f29c6ffff4f',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-05.jpg?alt=media&token=5ec63396-df38-4033-b4e6-9ef29b59bc65',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-06.jpg?alt=media&token=5c3a6313-7179-414c-9a23-7d8152a74baf',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-07.jpg?alt=media&token=e48ae2ba-a0f6-434e-8839-bd69b153833b',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-08.jpg?alt=media&token=d5000a7a-1a70-455b-9768-438604fd2a19',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-09.jpg?alt=media&token=ba0c5381-3b66-4060-885a-ea1ce8c2b7d2',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-10.jpg?alt=media&token=c9bc5382-0b66-40c4-94e3-144d2a2b0c05',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-11.jpg?alt=media&token=3e301b36-c750-487f-863d-4e9a3202ec8e',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-12.jpg?alt=media&token=c7491f13-6128-452a-9fa4-258829a126e1',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-13.jpg?alt=media&token=6f0aaab5-7b65-4a25-b2c9-e0c2147234f6',
                  'https://firebasestorage.googleapis.com/v0/b/nearwater-c02a9.appspot.com/o/9-key-images%2F9Keys-14.jpg?alt=media&token=76c57bc4-a668-42ab-8a69-f30a3f259569',
              ],
              // pos:pos1,
              // posMobile:yapi,
              // svg:svgYappi,
              // svgMobile:svgYappi,
              status: "notSale",
              about: "Клубный дом «9 ключей» находится на краю живописного склона в посёлке Ленинское. Хотя сКлубный дом «9 ключей» находится на краю живописного склона в посёлке Ленинское. Хотя с городом его связывают две транспортные магистрали — Зеленогорске шоссе и трасса Скандинавия — сам он удалён от шумных дорог." +
                  "<br></br>" +
                  "Название проекта обосновано: дом состоит из 9 индивидуальных блоков с собственной придомовой территорией. " +
                  "<br></br>" +
                  "Инфраструктура локации самодостаточна: в шаговой доступности школа, детский сад и спорткомплекс, магазины и общественный транспорт, и даже жемчужина Карельского перешейка — заповедник Хаапала." +
                  "<br></br>" +
                  "Лаконичная форма гармонично вписывает архитектуру дома в окружающую среду. Фасад выполнен из кирпича, стены — из монолитного железобетона, а кровля — из металла. " +
                  "<br></br>" +
                  "Жителям не нужно волноваться о коммуникациях, в проекте есть всё необходимое:" +
                  "",
              infrastructures: [
                  "Электричество 15 кВт",
                  "Газ",
                  "Центральный водопровод",
                  "Интернет",
              ],
              pluses: [
                  {
                      svg: svg1,
                      name: "Кол-во домов",
                      value: "9",
                  },
                  {
                      svg: svg2,
                      name: "Площадь",
                      value: "153.06",
                  },
                  {
                      svg: svg5,
                      name: "Срок сдачи",
                      value: "2025",
                  },
                  {
                      svg: svg6,
                      name: "Старт продаж",
                      value: "01.09.2024",
                  },
              ],
          },
        "Япилля": {
          id:1,
          dbName:"япилля",
          pos:pos1,
          posMobile:yapi,
          svg:svgYappi,
          svgMobile:svgYappi,
          status: "notSale",
          about: "Коттеджный посёлок Яппиля расположился на берегу большого Вишневского озера в 89 км от Санкт-Петербурга. Комплекс из 232 участков окружён живописной природой и находится в экологически чистом районе.\n" +
            "\n" +
            "Сочетание продуманной инфраструктуры, удобной транспортной доступности, собственной береговой линии и потрясающего ландшафта покорят даже убеждённого жителя мегаполиса.",
          infrastructures: [
            "Централизованный водопровод",
            "Электричество мощностью 15 кВт для каждого участка",
            "Высокоскоростной оптоволоконный интернет",
            "Прогулочные зоны",
            "Детские и спортивные площадки",
            "Внутреннее освещение, грунтовые дороги",
            "Огороженная территория, видеонаблюдение и умная система въезда",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "234",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "от 8 до 20",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "для видения дачного хозяйства",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "озеро Большое Вишневское",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "2024",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "01.05.2023",
            },
          ],
        },
        "Алыепаруса": {
          id:2,
          dbName:"алыеПаруса",
          pos:pos2,
          posMobile:alie,
          svg:alyeSVGTest,
          svgMobile:alyeSVGTest,
          // svg:svgAlie,
          // svgMobile:svgAlieMobile,
          status: "notSale",
          about: "Месторасположение сказочное: между Приморским шоссе и Заливом, в границах п. Пески. У Александра Грина «Алые Паруса» — повесть-феерия о вере и возвышенной мечте. О том, что чудо для близких людей может сделать каждый.\n" +
            "Мы учли это и предложили жителям нашего десятого посёлка редкое сочетание развитой инфраструктуры, продуманной архитектуры и неповторимой природы.",
          infrastructures: [
            "Единый архитектурный стиль въездной группы: контрольно-пропускной пункт, ресторан, магазин, удобная транспортная развязка;",
            "Коммуникации: магистральный газ, 15 кВт электричества, централизованный водопровод из трёх скважин, интернет;",
            "Внутреннее освещение, асфальтированные дороги;",
            "Детская и спортивная площадки;",
            "Прогулочные зоны;",
            "Подземная разводка инженерных сетей;",
            "Своя береговая линия Залива.",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "212",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "Земли поселений, ИЖС",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Посёлок Пески",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Финский залив",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "2024",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "01.09.2021",
            },
          ],
        },
        "Достоевский": {
          id:3,
          pos:pos3,
          dbName:"достоевский",
          posMobile:dosto,
          svg:svgDosto,
          svgMobile:svgDostoMobile,
          status: "notSale",
          about: "Коттеджный поселок Достоевский – комплекс из 17 участков в 40 минутах езды от Санкт-Петербурга. Небольшое количество соседей в сочетании с местной экосистемой располагают к спокойному проживанию среди живописных пейзажей.\n" +
            "Здесь удачно сочетаются преимущества одновременного проживания и в лесу, и в городе. Роскошный сосновый лес обеспечит не только вдохновляющие виды за окном, но и чистоту воздуха. Ведь хвоя обладает мощным антибактериальным действием, (поэтому именно в такой местности часто строят санаторно-оздоровительные комплексы).",
          infrastructures: [
            "Магистральный газ;",
            "Вода из центрального водопровода от внутрипоселковой скважины;",
            "Электричество мощностью 15 кВт для каждого участка;",
            "Высокоскоростной оптоволоконный интернет.",
            "Внутреннее освещение по всей территории;",
            "Асфальтированное дорожное покрытие (асфальтированный подъезд мы проложили до улицы Вокзальная);",
            "Современная площадка для игр детей и площадка для занятий спортом;",
            "Надежное ограждение, откатные ворота и круглосуточное видеонаблюдение.",
            "В пешей доступности детские сады, школа, медицинские учреждения и все условия для комфортной жизни современного человека среди хвойного леса.",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "17",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "от 12 до 17 соток",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Земли поселений, ИЖС",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Рощинское озеро",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "Сдан",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "20.06.2021",
            },
          ],
        },
        "БрусникRoad": {
          id:6,
          dbName:"брусникаРоад",
          pos:pos6,
          posMobile:brus,
          svg:svgBrus,
          svgMobile:svgBrusMobile,
          status: "notSale",
          about: "Дачный посёлок Брусника Road находится вблизи Брусничного шоссе в Селезневском сельском поселении Выборгского района, в 150 км от Санкт-Петербурга. Без пробок до него можно доехать за 2 часа по красивейшей трассе Скандинавия, с которой есть удобный съезд прямо к поселку. ",
          infrastructures: [
            "Электричество 15 кВт;",
            "Грунтовые дороги;",
            "Внутрипоселковое освещение;",
            "Пункт охраны;",
            "Ограждение по периметру, автиоматические ворота;",
            "Детская и спортивная площадки;",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "93",
            },
            {
              svg: svg2,
              name: "Населённый пункт",
              value: "СПК Шестаковский",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Для ведения дачного хозяйства",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Озеро Кленовское, Сайменский канал",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "1.05.2021",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "05.2022",
            },
          ],
        },
        "РеполаПарк": {
          id:5,
          dbName: "реполаПарк",
          pos:pos7,
          posMobile:repolaPark,
          svg:svgRepoPark,
          svgMobile:svgRepoPark,
          status: "notSale",
          about: "Поселок открывает удивительные перспективы для полноценного отдыха, доступные по умолчанию владельцу каждого участка:\n" +
            "собственная набережная и прогулочные зоны;\n" +
            "выход в открытый водоем (Выборгский залив) с обустроенным пирсом;\n" +
            "густой хвойный лес с завораживающими пейзажами и целебным воздухом." +
            "Последний пункт приведен без преувеличения. Научно доказана способность хвои насыщать пространство фитонцидами. Эти биологически активные соединения подавляют развитие болезнетворных микроорганизмов.",
          infrastructures: [
            "Электросеть с мощностью 15 кВт для каждого участка;",
            "Оптоволоконный интернет;",
            "Центральный водопровод из внутрипоселковой скважины.",
            "Оборудование коммуникационных систем под землей обеспечивает безопасность и эстетичный вид поселка за счет отсутствия на улицах столбов с проводами. Также это автоматически избавляет от забот с восстановлением сетей после непогоды (как это часто бывает с наземным электроснабжением).",
            "Систему круглосуточного видеонаблюдения с ограждениями и пропускным режимом",
            "Асфальтированное покрытие дорог;",
            "Внутреннее освещение территории;",
            "Приятно оформленные и ухоженные зоны для прогулок;",
            "Качественно оборудованные детская и спортивная площадки.",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "46",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "для дачного строительства",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Выборгский район, посёлок Подборовье",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "На берегу\n Финского залива",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "Сдан",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "",
            },
          ],
        },
        //снизу распроданные участки
        "Адмирал": {
          id:4,
          pos:pos4,
          posMobile:admi,
          svg:svgAdmi,
          svgMobile:svgAdmiMobile,
          status: "notSale",
          about: "Коттеджный посёлок Адмирал расположен в окружении живописного леса на берегу Нахимовского озера. Уникальный природный ландшафт, собственная береговая линия, отличная транспортная доступность, безопасность и развитая внутренняя инфраструктура. Сюда легко добраться, отсюда не хочется уезжать.",
          infrastructures: [
            "Электрическая мощность — 15 кВт на каждый участок",
            "Централизованный водопровод из двух внутрипоселковых скважин (одна из них резервная)",
            "Подземная разводка всех инженерных систем, в том числе освещения",
            "Ливневая (дождевая) канализация открытого типа",
            "Двухполосные внутриквартальные асфальтированные дороги, по которым обеспечивается подъезд к каждому участку",
            "Детская площадка",
            "Спортивная площадка",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "50",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "Земли поселений",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Посёлок Овсяное",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Нахимовское озеро",
            },
            {
              svg: svg5,
              name: "Сдан",
              value: "20 марта 2020",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "1.09.2021",
            },
          ],
        },
        "Репола": {
          id:7,
          pos:pos5,
          dbName: "репола",
          posMobile:repo,
          svg:svgRepola,
          svgMobile:svgRepolaMobile,
          status: "Sale",
          about: "'Репола Парк' отличается удобным расположением относительно города, готовой инфраструктурой и уникальными природно-экологическими характеристиками. Посёлок расположен на живописном берегу Финского залива и полностью готов для проживания, в том числе круглогодичного. ",
          infrastructures: [
            "Подземная электрификация участков (15квт)",
            "Высокоскоростной интернет (оптоволокно)",
            "Круглогодичное водоснабжение",
            "Устройство дорожного покрытия из ЩПС",
            "Ливневая (дождевая) канализация открытого типа",
            "Огороженная территория, круглосуточная охрана и пропускной режим",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "92",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "от 12 до 17 соток",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Для ведения дачного хозяйства",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Выборгский залив",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "Лето 2024",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "01.06.2021",
            },
          ],
        },
        "Акварель": {
          id:8,
          pos:pos8,
          posMobile:akva,
          svg:svgAkva,
          svgMobile:svgAkva,
          status: "Sale",
          about: "Коттеджный посёлок “Акварель” расположен на берегу Нахимовского озера в окружении смешанных лесов. С каждого участка открывается живописный вид на водную гладь, что является отличительным преимуществом посёлка. Инженерные системы уже сданы в эксплуатацию, территория огорожена и находится под охраной.",
          infrastructures: [
            "Электрическая мощность — 15 кВт на каждый участок",
            "Централизованный водопровод из внутрипоселковой скважины",
            "Ливневая канализация открытого типа",
            "Асфальтированные дороги",
            "Внутреннее освещение",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "19",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "Посёлок Овсяное",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Земли поселений",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Озеро Нахимовское",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "01.08.2019",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "Сдан",
            },
          ],
        },
        "Чуфрино": {
          id:9,
          pos:pos9,
          posMobile:chuf,
          svg:svgChuf,
          svgMobile:svgChuf,
          status: "Sale",
          about: "Коттеджный поселок “Чуфрино” расположен в окрестностях Рощинского городского поселения, в котором уже сложилась развитая социальная инфраструктура. Непосредственная близость Нахимовского озера, густой хвойный бор и равная комфортная удаленность от Санкт-Петербурга и Выборга сделали коттеджный посёлок “Чуфрино” идеальным местом для загородного строительства.",
          infrastructures: [
            "Водоснабжение из скважин",
            "Электрификация участков и освещение улиц",
            "Качественные грунтовые дороги ",
            "Огороженная территория, контрольно-пропускной пункт",
            "Школы, детские сады, поликлиника (в 15 км)",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "34",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "от 12 до 16 соток",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "Земли поселений",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Озеро Нахимовское",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "Сдан и заселён",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "Сдан",
            },
          ],
        },
        "Верхняядача": {
          id:10,
          pos:pos10,
          posMobile:verch,
          svg:svgVerch,
          svgMobile:svgVerch,
          status: "Sale",
          about: "Коттеджный поселок Верхняя дача расположен в Выборгском районе в живописных окрестностях населённого пункта Зелёная роща. Посёлок находится в окружении смешанного леса в непосредственной близости от побережья Финского залива и небольших озёр. ",
          infrastructures: [
            "Электрификация участков (15квт) и уличное освещение",
            "Централизованный водопровод ",
            "Качественные грунтовые дороги",
            "Ливневая (дождевая) канализация открытого типа",
            "Огороженная территория, круглосуточная охрана",
          ],
          pluses: [
            {
              svg: svg1,
              name: "Кол-во участков",
              value: "132",
            },
            {
              svg: svg2,
              name: "Площадь участков",
              value: "Для ведения дачного строительства",
            },
            {
              svg: svg3,
              name: "Статус земли",
              value: "для дачного строительства",
            },
            {
              svg: svg4,
              name: "Водоёмы",
              value: "Финский залив",
            },
            {
              svg: svg5,
              name: "Срок сдачи",
              value: "1.05.2017",
            },
            {
              svg: svg6,
              name: "Старт продаж",
              value: "Сдан",
            },
          ],
        },
      },
    }
  },
  async created () {
      await this.getDataFromFirebase();


      this.$nextTick(() => {
        let listNode;
        const dbName = this.data?.[this.id].dbName;
        const currentPos = unref(this.currentPos);
        const width = this.width;
        const currentDiv = document.getElementById("app");

        if (dbName === 'япилля') {
          // listNode = document.getElementsByTagName("polygon");
          listNode = document.getElementsByClassName("real-house");
        } else if (dbName === 'алыеПаруса' || dbName === 'реполаПарк') {
          listNode = document.getElementsByClassName("real-house");
        } else {
          listNode = document.getElementsByTagName("path");
        }
        setTimeout(() => {
          []?.forEach.call((listNode), function(node) {

            if (node.id !== '') {
              let arr = node.id.split("_");
              if (arr[0] === "infotext" || arr[0] === "myHouseMob" || arr[0] === "myHouse") {

                const index = node.id.replace(/[^\d]/g, ''); // оставляет только числа
                const currentPosItem = currentPos.filter((pos) => {
                  return String(pos.id) === index
                });

                const status = currentPosItem[0]?.status;
                if (dbName === "достоевский") {
                  if (status === "продан") {
                    node.classList.replace("st1", "st7")
                  } else if (status === "забронирован") {
                    node.classList.replace("st1", "st6")
                  }
                }
                if(dbName === "алыеПаруса") {
                  if (status === "продан") {
                    // node.classList.replace("fil3", "fil5")
                    node.classList.replace("st5", "st6")
                  } else if (status === "забронирован") {
                    // node.classList.replace("fil3", "fil6")
                    node.classList.replace("st5", "st4")
                  }
                }

                if (dbName === "брусникаРоад") {
                  if (status === "продан") {
                    node.classList.replace("st1", "st6")
                  } else if (status === "забронирован") {
                    node.classList.replace("st1", "st4")
                  }
                }
                if (dbName === "репола") {
                  node.classList.replace("fil9", "fil2")
                  if (status === "продан") {
                    node.classList.replace("fil5", "fil9")
                  } else if (status === "забронирован") {
                    node.classList.replace("fil5", "fil8")
                  } else if (status === "свободен") {
                    node.classList.replace("fil5", "fil11")
                  }
                }
                if (dbName === "япилля") {
                  if (status === "продан") {
                    node.classList.replace("st5", "st12")
                  } else if (status === "забронирован") {
                    node.classList.replace("st5", "st13")
                  }
                }
                  if (dbName === "реполаПарк") {
                      if (status === "продан") {
                          node.style.fill = '#E37156';
                      } else if (status === "забронирован") {
                          node.style.fill = '#606EA9';
                      }
                  }





                  let newDiv;
                let divIn1;
                let divIn2;
                let divIn3;
                let divIn4;
                let divIn5;
                let divIn6;
                let intervalId;
                let setNextImage;
                const handleStart = (event) => {
                  window.requestAnimationFrame(() => {

                    // console.log(1, "e=",e.target)
                    // console.log(2, "node=",node.fill)
                    // console.log(3, "node=",node.firstChild)
                    node.style.border = '5px dotted orange';
                    newDiv = document.createElement("div");
                    divIn1 = document.createElement("div");
                    divIn2 = document.createElement("div");
                    divIn4 = document.createElement("div");
                    divIn5 = document.createElement("img");
                    divIn6 = document.createElement("div");
                    if (currentPosItem[0]?.photo) {
                      // console.log(44444, "currentPos=",currentPosItem)

                      let i = 0;

                      setNextImage = function () {
                        divIn5.setAttribute('src', currentPosItem[0]?.photo[i]);
                        i = (i + 1) % currentPosItem[0]?.photo.length; // Increment index and loop back to 0 when reaching the end
                      }

                      setNextImage();

                      intervalId = setInterval(setNextImage, 2000);
                      // If needed, you can also clear the interval when required, for example:
                      // clearInterval(intervalId);

                      // divIn5.url = currentPos[0];
                      divIn5.style.width = '80%';
                    }

                    if (currentPosItem[0]?.info) {
                      newDiv.style.gap = '16px';
                      divIn6.innerHTML = currentPosItem[0]?.info;
                      divIn6.style.textAlign = 'center';
                    }




                    divIn1.textContent = "УЧАСТОК НОМЕР №" + index;
                    divIn2.textContent = "Площадь:" + currentPosItem[0]?.square + "кв/м";
                    divIn4.textContent = currentPosItem[0]?.status;

                    newDiv.style.width = '300px';
                    // newDiv.style.height = '150px';
                    newDiv.style.minHeight = '150px';
                    divIn1.style.height = '20px';
                    divIn4.style.height = '20px';
                    newDiv.style.borderRadius = '16px';
                    newDiv.style.zIndex = '3000';
                    newDiv.style.position = 'absolute';
                    newDiv.style.position = '-webkit-absolute';
                    newDiv.style.top = '0';
                    // newDiv.style.top = '50%';
                    // newDiv.style.left = '20%';
                    // newDiv.style.transform = 'translate(-50%, -50%)';
                    newDiv.style.zIndex = '1000';
                    newDiv.style.backgroundColor = 'gray';
                    newDiv.style.margin = '0 auto';
                    newDiv.style.display = 'flex';
                    newDiv.style.justifyContent = 'space-between';
                    newDiv.style.flexDirection = 'column';
                    newDiv.style.alignItems = 'center';
                    newDiv.style.padding = '16px';
                    newDiv.style.fontFamily = 'Poiret One';
                    newDiv.style.fontWeight = '700';
                    if (width < 768) {
                      newDiv.style.transform = 'scale(0.6)';
                    //   newDiv.style.top = '50%';
                    //   newDiv.style.left = '50%';
                    }
                    divIn1.style.margin = '0 auto';
                    divIn1.style.backgroundColor = 'white';
                    divIn1.style.borderRadius = '8px';
                    divIn1.style.width = '100%';
                    divIn1.style.textAlign = 'center';
                    divIn2.style.height = '20px';
                    divIn2.style.margin = '0 auto';
                    divIn4.style.margin = '0 auto';
                    if (currentPosItem[0]?.price) {
                      if (status !== "продан") {
                        divIn3 = document.createElement("div");
                        divIn3.textContent = currentPosItem[0]?.price ?? "Цена:" + currentPosItem[0]?.price;
                        divIn3.style.height = '20px';
                        divIn3.style.margin = '0 auto';
                      }
                    }
                    // if (width > 768) {
                    //расчёт для появления справа от мыши
                    // const mouseX = event.clientX;
                    // const mouseY = event.clientY;
                    //
                    // // Calculate the position of the modal window
                    // const modalWidth = newDiv.offsetWidth;
                    // const modalHeight = newDiv.offsetHeight;
                    //
                    // const windowWidth = window.innerWidth;
                    // const windowHeight = window.innerHeight;
                    //
                    // let modalX = Math.min(mouseX + 10, windowWidth - modalWidth);
                    // // const modalX = Math.min(mouseX + 10, windowWidth);
                    // let modalY = Math.min(mouseY + 10, windowHeight - modalHeight);
                    // // const modalY = Math.min(mouseY + 10, windowHeight);
                    // if (width < 768) {
                    //   modalX -= 60
                    //   modalY -= 60
                    // }


                    // }
                    newDiv.style["-webkit-backface-visibility"] = "hidden";
                    newDiv.style["-webkit-transform"] = "translateZ(0)";
                    if(width < 768) {
                      newDiv.style.width = '';
                      newDiv.style.height = '';
                      newDiv.style.fontSize = '12px';
                      divIn1.textContent = "НОМЕР №" + index;
                    }



                    newDiv.style.zIndex = '9999';
                    // add the newly created element and its content into the DOM
                    // Ваш код для создания и добавления элемента newDiv
                    document.body.insertBefore(newDiv, currentDiv);
                    // document.body.appendChild(newDiv);
                    // currentDiv.appendChild(newDiv)


                    // document.body.appendChild(newDiv);
                    newDiv.appendChild(divIn1);
                    newDiv.appendChild(divIn2);
                    if (currentPosItem[0]?.price && status !== "продан") {
                      newDiv.appendChild(divIn3);
                    }
                    newDiv.appendChild(divIn4);
                    newDiv.appendChild(divIn5);
                    newDiv.appendChild(divIn6);

                    //где появится
                    const mouseX = event.pageX;
                    const mouseY = event.pageY;
                    const modalWidth = newDiv.offsetWidth;
                    const windowWidth = window.innerWidth;

                    let modalX = (mouseX + modalWidth + 10 < windowWidth) ? mouseX + 10 : mouseX - modalWidth - 10;
                    const modalY = mouseY + 10;

                    // Set the position of the modal window
                    newDiv.style.left = modalX + "px";
                    newDiv.style.top = modalY + "px";
                  })

                }

                if (dbName === "достоевский" ||
                  dbName === "алыеПаруса" ||
                  dbName === "брусникаРоад"
                  || dbName === "репола"
                  || dbName === "япилля"
                  || dbName === "реполаПарк"
                ) {
                  window.addEventListener('popstate', function () {
                    document.body.removeChild(newDiv);
                  });
                  node?.addEventListener('click', handleStart);
                  // node?.addEventListener('mouseenter', handleStart);
                  // node?.addEventListener('touchstart', handleStart);

                  // const elementToClose = document.getElementById('yourElementId');
                  // Добавьте слушатель события 'click' к документу
                  document.addEventListener('click', function(event) {
                    event.preventDefault();
                    const targetElement = event.target;

                    if (!newDiv) {
                      return;
                    }
                    // Проверьте, является ли целевой элемент потомком элемента, который должен закрыться
                    if (newDiv) {
                      if (!newDiv.contains(targetElement) && newDiv.parentNode === document.body) {
                        // Здесь вы можете вызвать функцию для обработки кликаутсайд
                        document.body.removeChild(newDiv);
                      }
                    }
                  });
                }


                const handleEnd = () => {
                  clearInterval(intervalId);

                  window.requestAnimationFrame(() => {
                    document.body.removeChild(newDiv)
                    // currentDiv.removeChild(newDiv)
                  })
                  // currentDiv.removeChild(newDiv)
                }
                console.log(handleEnd)

                if (dbName === "достоевский" ||
                  dbName === "алыеПаруса" ||
                  dbName === "брусникаРоад"
                  || dbName === "репола"
                  || dbName === "япилля") {
                  // node.addEventListener('mouseleave', handleEnd);
                  // node.addEventListener('touchend', handleEnd);

                }




              }

            }
          });
        }, 700)


      })

  },
  methods: {
    async getDataFromFirebase() {
      if (this.data?.[this.id].dbName === undefined) {
        return;
      }
      const querySnaps = await getDocs(query(collection(dbF, this.data?.[this.id].dbName)));
      const docRefs = [];

      querySnaps.forEach((pos) => {
        this.currentPos.push(unref(pos.data()))

        if (unref(pos.data())['бронь']) {
          const fieldData = unref(pos.data())['бронь'];
          const id = unref(pos.data().id);
          let currentDate = new Date().toLocaleDateString();

          if (new Date(fieldData) >= new Date(currentDate)) {
            const docRef = doc(dbF, this.data?.[this.id].dbName, String(id));
            docRefs.push(docRef);
          }
        }
      });

      for (const docRef of docRefs) {
        const updateData = { status: 'свободен', 'бронь': '' };
        await updateDoc(docRef, updateData);
      }
    },
  },

}
</script>
<style scoped>
.wrapper-9-key-images{
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    height: auto;
    margin: 0 auto;
    gap: 20px;
}
.svg-full-width {
    width: 100%;
    height: 100%;
}
polygon:hover {
  color: black;
}
.container{
  max-width: 1280px;
  margin: 0 auto;
  position: relative;


  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  -webkit-appearance: none!important;
  overflow: hidden;
  vertical-align: middle;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.box{
  position: absolute;
  opacity: 0.8;  /* для демонстрации  */
}
.stack-top{
  z-index: 9;
}


.icon_info_item__title{
  text-align: center;
  font-size: 14px;
}
.parallax {
  /* The image used */
  /*background-image: url("../assets/alieParusa.PNG");*/

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.village-title{
  font-size: 48px;
  margin-bottom: 12px;
  margin-top: 24px;
  text-indent: 24px;
}
.about-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-items: center;
  max-width: 768px;
  margin-bottom: 18px;
  padding: 16px;
}
.wrapper-li{
  display: flex;
  justify-content: center;

}
.row-li{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 4px;
  font-size: 22px;
}
.container-li{
  padding: 16px;
  font-size: 14px;
  width: 768px;
}
.container-village{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
li{
  list-style-type: none;
}
.rb-icon_info {
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: center;
}
.icon_info_item{
  display: flex;
  flex-direction: column;
  gap: 4px;
  /*padding: 16px;*/
}
.icon_info_item__icon{
  height: 60px;
  width: 60px;
  margin: 0 auto;
}
.icon_info_item__desc{
  font-size: 16px;
  font-weight: 700;
}
.buyed_house{
  cursor: blue;
}
@media (max-width: 768px) {
  .container{
    left: -50px;
  }
  .rb-icon_info{
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  .icon_info_item{
  }
  .icon_info_item__icon{
    height: auto;
  }
  .village-title{
    font-size: 32px;
  }
  .row-li{
    font-size: 17px;
  }
  .text-text{
    font-size: 24px;
  }
}
</style>
