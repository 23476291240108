<script>
import VillageCard from '@/components/VillageCard'
// import AboutTitle from '@/components/AboutTitle'

export default {
  name: 'VideoFon',
  components: {
    VillageCard,
    // AboutTitle,
  },
  mounted () {
  },

    methods: {
        toVillage() {
            this.isShow = false;
            this.$router.push('/poselki/клубный-дом');
        },
    }
}
</script>

<template>
  <video v-if="false" poster="../assets/video/img-for-video-fon.png" video autoplay loop muted playsinline id="myVideo">
    <source src="../assets/video/bg_video_fon_mobile.mp4" type="video/mp4">
  </video>

<!--    <div v-else class="background-img"></div>-->
    <div v-else class="banner-container">
        <img class="background-img" src="../../src/images/banner-16-9.png">
        <div class="invisibility-btn" @click="toVillage"></div>
    </div>


<!--  <AboutTitle/>-->
  <VillageCard/>

</template>

<style scoped>

.background-img {
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    width: 100%;
    /*height: 100%;*/
    /*position: absolute; !* Абсолютное позиционирование для обеспечения корректного отображения *!*/
}
.banner-container {
    position: relative; /* Устанавливаем относительное позиционирование для контейнера */
    width: 100%; /* Ширина контейнера на 100% */
    height: auto; /* Высота автоматически подбирается по содержимому */
}

.invisibility-btn {
    z-index: 156; /* Слои, чтобы кнопка была над картинкой */
    width: 50%; /* Ширина кнопки */
    height: 35%; /* Высота кнопки */
    /*background-color: blue; !* Цвет фона (можно убрать, если кнопка должна быть невидимой) *!*/
    position: absolute; /* Абсолютное позиционирование для кнопки */
    top: 35%; /* Положение кнопки по вертикали (можно настроить) */
    right: 0; /* Положение кнопки по горизонтали */
    pointer-events: auto; /* Убедитесь, что кнопка может реагировать на события клика */
}


video {
  object-fit: cover;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  /*position: fixed;*/
  /*right: 0;*/
  /*bottom: 0;*/
  width: 100%;
  /*min-height: 500px;*/
  /*background-position: center center;*/
}

-webkit-media-controls-panel {

  display: none !important;

  -webkit-appearance: none;

}

--webkit-media-controls-play-button {

  display: none !important;

  -webkit-appearance: none;

}

-webkit-media-controls-start-playback-button {

  display: none !important;

  -webkit-appearance: none;

}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100vh;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}


</style>
