<template>
  <div class="grid-container">
    <div v-for="(news, index) in newses" class="grid-item" :key="index">
      <img :src="news.src">


      <div
        class="p-wrapper"
        @mouseenter="isFullText = index"
        @mouseleave="isFullText = index"
      >
        <p
          class="desc-text other-text-style"
          v-if="isFullText !== index"
          style="white-space: nowrap;"
        >
          {{ news.desc[0] }}
        </p>
        <transition-group name="bounce">
          <p
            v-show="isFullText === index"
            class="desc-text shake"
            v-for="(newsItem, i) in news.desc"
            :key="i"
          >
            {{ newsItem }}
          </p>
        </transition-group>

      </div>
    </div>

  </div>

</template>

<script>
import news1 from "../assets/imgsNews/news1.jpg";
import news2 from "../assets/imgsNews/news2.jpg";
import news3 from "../assets/imgsNews/news3.jpg";
import news4 from "../assets/imgsNews/news4.jpg";
import news5 from "../assets/imgsNews/news5.jpg";
import news6 from "../assets/imgsNews/news6.jpg";

export default {
  name: 'NewsWater',
  components: {
  },
  data() {
    return {
      isFullText: false,
      newses: [
        {
          id: 1,
          title: "some title",
          src: news1,
          desc: [
            `В Репола Парк \n мы уже готовим понтоны к навигации! Совсем скоро наши резиденты смогут их опробовать!`,
          ]
        },
        {
          id: 2,
          title: "some title",
          src: news2,
          desc: [
            "Все наши посёлки находятся на севере Ленинградской области.",
            "Выборгский район пользуется популярностью у петербуржцев. Десятки озёр поблизости," +
            "живописная природа и хорошая экология — весомые аргументы для городских жителей.",
            "Но преимущества такого расположения не заканчиваются на колоритной окружающей среде." +
            "Вот ещё 3 причины приобрести участок здесь:",
            "▫️Удобная транспортная развязка позволяет Вам легко добраться до посёлков на личном или общественном транспорте.",
            "▫️Развитая инфраструктура делает Вашу жизнь за городом комфортной настолько, словно Вы не уезжали из мегаполиса.",
            "▫️Цена участков соответствует качеству жизни, который будет доступен Вам круглый год.",
          ]
        },
        {
          id: 3,
          title: "some title",
          src: news3,
          desc: [
            "В коттеджном поселке #Адмирал начали работы по установке спортивного оборудования и укладке искусственного газона в зоне игровых видов спорта, а также резинового покрытия в зоне тренажеров!",
          ]
        },
        {
          id: 4,
          title: "some title",
          src: news4,
          desc: [
            "Все наши посёлки находятся на севере Ленинградской области.",
            "Выборгский район пользуется популярностью у петербуржцев. Десятки озёр поблизости, живописная природа и хорошая экология — весомые аргументы для городских жителей.",
            "Но преимущества такого расположения не заканчиваются на колоритной окружающей среде. Вот ещё 3 причины приобрести участок здесь:",
            "▫️Удобная транспортная развязка позволяет Вам легко добраться до посёлков на личном или общественном транспорте.",
            "▫️Развитая инфраструктура делает Вашу жизнь за городом комфортной настолько, словно Вы не уезжали из мегаполиса.",
            "▫️Цена участков соответствует качеству жизни, который будет доступен Вам круглый год.",
          ]
        },
        {
          id: 5,
          title: "some title",
          src: news5,
          desc: [
            "Публикуем первые кадры с будущей купальной зоны коттеджного поселка «Яппиля»! Напоминаем, что просмотры участков уже начались!",
          ]
        },
        {
          id: 6,
          title: "some title",
          src: news6,
          desc:[
            "В КП АЛЫЕ ПАРУСА начали работы по укладке оптического волокна и кабелей освещения!"
          ]
        },
      ],
    }
  },
}
</script>

<style scoped>
img{
  width: 350px;
  height: 400px;
  object-fit: cover;
}
.desc-text{
  text-overflow: ellipsis;
  overflow: hidden;
  text-indent: 24px;
  width: 400px;


}
.grid-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  width: 70vh;
  margin: 0 auto;
  gap: 16px;
  row-gap: 32px;
  padding: 16px;
}
.grid-item{
  width: 400px;
}
@media (max-width: 1200px) {
  .grid-container{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .grid-container{
    grid-template-columns: repeat(1, 1fr);
  }
}
/*p{*/
/*  border: 2px solid #ccc;*/
/*}*/
.bounce-enter-active {
  animation: bounce-in .5s ease-out both;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse ease-in both;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
/*p {*/
/*  width: 400px;*/
/*  height: 30px;*/
/*  line-height: 30px;*/
/*  margin: 0 auto;*/
/*  border: 2px solid #ccc;*/
/*  font-size: 14px;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*  transition: all .5s;*/
/*}*/

/*p:hover {*/
/*  overflow: inherit;*/
/*  text-overflow: inherit;*/
/*  white-space: inherit;*/
/*  height: auto;*/
/*}*/

.st0{fill:#94BC71;}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#5B4374;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#ECEDED;}
.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#E37156;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#9EC8D3;}
.st5{fill:#ECEDED;}
.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#CF7456;}
.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#6572AD;}
.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#5D4477;}
.st9{fill-rule:evenodd;clip-rule:evenodd;fill:#A3CCD7;}
.st10{fill-rule:evenodd;clip-rule:evenodd;fill:#EDECEC;}
.st11{fill:#F7B275;}
.st12{fill:#3E8055;}
.st13{fill-rule:evenodd;clip-rule:evenodd;fill:#E5B378;}
.st14{fill:#606EA9;}
.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#EDECED;}
.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#3E8055;}
.st17{fill-rule:evenodd;clip-rule:evenodd;fill:#F7B275;}
.st18{fill-rule:evenodd;clip-rule:evenodd;fill:#94BC71;}
.st19{fill-rule:evenodd;clip-rule:evenodd;fill:#929946;}
</style>
