<template>
<!--    <BannerModal v-if="route.name === 'HelloWorld'"/>-->
<!--    <BannerModal v-if="false"/>-->

    <MainHeader/>

  <div id="main-scroll" class="title" v-if="this.route.path !== '/'">
    {{title}}
  </div>

    <router-view style="margin-top: 16px; margin-bottom: 16px">
    </router-view>

  <FormContacts v-if="this.route.path !== '/'" />
  <FooterCompany />


</template>

<script>
import MainHeader from '@/components/MainHeader';
import { useRoute } from 'vue-router';
import FooterCompany from '@/components/FooterCompany';
import FormContacts from '@/components/FormContacts';
// import BannerModal from '@/components/BannerModal';

export default {
  name: 'HelloWorld',
  components: { FooterCompany, MainHeader, FormContacts },
  // components: {BannerModal, FooterCompany, MainHeader, FormContacts },
  mounted () {
    this.route = useRoute()
    // let location = window.location.pathname;
  },
  watch: {
    // whenever question changes, this function will run
    'route.path'(newVal) {
      let obj = {
        "/villages": "У ВОДЫ / Посёлки",
        "/about": "У ВОДЫ / О компании",
        "/map": "У ВОДЫ / Карта",
        "/news": "У ВОДЫ / Новости",
        "/contacts": "У ВОДЫ / Контакты",
        "/poselki/Япилля": "КОТТЕДЖНЫЙ ПОСЁЛОК ЯПИЛЛЯ",
        "/poselki/Алыепаруса": "КОТТЕДЖНЫЙ ПОСЁЛОК АЛЫЕ ПАРУСА",
        "/poselki/Достоевский": "КОТТЕДЖНЫЙ ПОСЁЛОК ДОСТОЕВСКИЙ",
        "/poselki/Адмирал": "КОТТЕДЖНЫЙ ПОСЁЛОК АДМИРАЛ",
        "/poselki/РеполаПарк": "КОТТЕДЖНЫЙ ПОСЁЛОК РЕПОЛА ПАРК",
        "/poselki/БрусникRoad": "КОТТЕДЖНЫЙ ПОСЁЛОК БРУСНИК ROAD",
        "/poselki/Репола": "КОТТЕДЖНЫЙ ПОСЁЛОК РЕПОЛА",
        "/poselki/Акварель": "КОТТЕДЖНЫЙ ПОСЁЛОК АКВАРЕЛЬ",
        "/poselki/Чуфрино": "КОТТЕДЖНЫЙ ПОСЁЛОК ЧУФРИНО",
          "/poselki/Верхняядача": "КОТТЕДЖНЫЙ ПОСЁЛОК ВЕРХНЯЯ ДАЧА",
          "/poselki/клубный-дом": "КЛУБНЫЙ ДОМ - 9 КЛЮЧЕЙ",
      }
      this.location = newVal;
      this.title = obj[newVal];
    }
  },
  data() {
    return {
      route: "",
      title: "",
      location: "",
      name: "",
      phone: "",
      email: "",
      message: "",
        isShowBanner: false,
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.title {
  z-index: 1;
  color: white;
  font-size: 40px;
  font-weight: 700;
  min-height: 100px;
  align-content: center;
  width: 100%;
  background-image: url("../assets/BG-fon_green.png");
  background-repeat: no-repeat;
  padding: 24px;
}


@media (max-width: 768px) {
  .title {
    min-height: 80px;
    font-size: 22px;
  }
}
</style>
