import { createWebHistory, createRouter } from "vue-router";
// import HelloWorld from "/src/components/HelloWorld";
// import MapOffice from "/src/components/MapOffice";
import AboutCompany from "/src/components/AboutCompany";
import NewsWater from "/src/components/NewsWater";
import ContactsCompany from "/src/components/ContactsCompany";
import VillageCard from "/src/components/VillageCard";
import VideoFon from "/src/components/VideoFon";
import VillageAbout from '@/components/VillageAbout';

const routes = [
  {
    path: "/",
    name: "HelloWorld",
    component: VideoFon,
  },
  {
    path: '/poselki/:id',
    name: VillageAbout,
    component: VillageAbout,
  
  },
  {
    path: "/villages",
    name: "VillageCard",
    component: VillageCard,
    // children: [
    //   {
    //     path: 'VillageAbout',
    //     name: 'settings-profile',
    //     component: SettingsProfileView
    //   }
    // ]
  },
  {
    path: "/about",
    name: "AboutCompany",
    component: AboutCompany,
  },
  // {
  //   path: "/map",
  //   name: "MapOffice",
  //   component: MapOffice,
  // },
  {
    path: "/news",
    name: "NewsWater",
    component: NewsWater,
  },
  {
    path: "/contacts",
    name: "ContactsCompany",
    component: ContactsCompany,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
